import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import StoolsGalleryContent from "../components/GalleryContent/StoolsGalleryContent"

const StoolsGallery = () => (
  <Layout>
    <SEO title="Stools Gallery" />
    <StoolsGalleryContent />
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default StoolsGallery
